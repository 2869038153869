/***** Primary Palette *****/

// Greens
$acds-primary: #00D6C9; 
$acds-primary-400: #3FD9CF; 
$acds-primary-600: #1D958D; 
$acds-primary-700: #156A65; 
$acds-first-gen-green: #00A59B;

$brand-light-green: #6ECEC1; 
$brand-lighter-green: #bfe8e6; 

// Oranges
$acds-secondary: #FE6320;
$brand-yellow: #FF9D00;
$acds-secondary-lighter: #ffc49f;
 

//  Blues
$brand-blue: #014966;
$brand-blue-darker: #003153;
$hero-blue-dark: #112f61;
$hero-blue-darker: rgb(11, 20, 37);

/***** Grayscale Palette *****/

$gray-overlay: rgb(0,0,0,0.75);
$black: #000000;



/* Start New css */

$white: #ffffff;
$gray-200: #E5E7EB;
$gray-300: #D1D5DB;
$gray-400: #8f97a6;
$gray-500: #6B7280;
$gray-700: #374151;
$gray-800: #1F2A37;
$gray-900: #111827;

$primary-50: #EAFBFA;
$primary-100: #BFF2EF;
$primary-400: #3FD9CF;
$primary-500: #26BFB4;
$primary-600: #1D958D;
$primary-700: #156A65;
$primary-800: #0D403C;

/* End New css */