// headers
$main-nav-height: 102px;
$total-header-height: $main-nav-height;
$total-header-height-shrink: 115px;

// dashboard panels
$border-radius-panels: 0.5rem;
$border-radius-records: 2rem;
$padding-panels: 1.5rem;

// containers
$border-radius-container: 1.5rem;
$padding-container: 2rem;
$lrg-container-padding: 3rem;
$sm-container-padding: 1.5rem;

// buttons
$border-radius-buttons: 100px;
$border-radius-buttons-new: 8px;

// box-shadows
$box-shadow-light: 0 2px 5px rgb(0 0 0 / 10%);
$box-shadow-heavy: 0px 3px 9px rgba(0,0,0,0.12),
0px 3px 18px rgba(0,0,0,0.08);
$box-shadow-heaviest: 0px 10px 10px 0px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10);

// borders
$border-separator: 1px solid #cccccc80;
$border-separator-dark-bg: 1px solid #cccccc40;


/* Start New CSS */
$box-shadow-xl: 0px 10px 10px 0px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10);
$box-shadow-xxl: 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px 0px rgba(0, 0, 0, 0.04);

$border-radius-sm: 8px;
$border-radius-lg: 16px;
$rounded-lg: 8px;
$btn-padding-sm: 8px;
$btn-padding-m:12px 20px;

$btn-padding-lg: 14px 12px;

$btn-padding-xl: 12px 20px;
$header-border-radius: 16px;

/* End New CSS */