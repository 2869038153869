@import './assets/styles/vars';

.document-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 4rem;
}

.document-viewer {
  display: flex;
  flex-direction: column;
  padding: 10rem 12rem;
  gap: 48px;
}

.document-viewer-container {
  padding: 3rem 9rem;
}

.heading2 {
  @include h2-desktop;

  @media (max-width: $tablet-breakpoint) {
    @include h3-desktop;
    font-weight: 700;
  }

  @media (max-width: $mobile-breakpoint) {
    @include h2-mobile-lg;
  }
}


.table-of-contents h2 {
  color: $gray-900;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
  color: $gray-900;
}

.table-of-contents ol {
  padding-left: 20px;
}

.table-of-contents li {
  margin-bottom: 1em;
  padding: 5px;
}

.table-of-contents a {
  color: $gray-700;
  display: flex;
  align-items: center;
}

.table-of-contents a:hover {
  text-decoration: underline;
}

.arrow-icon {
  margin-left: 0.5em;
  width: 1em;
  height: 1em;
}


.highlighted-title-text {
  color: $gray-900;
}

.title-text {
  color: $gray-700;
}