@import './assets/styles/vars';
html, body {
  height: 100%;
  width: 100%;
  margin: 0px;
}

body {
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

