@import "layout";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

// Fonts
$font-brand-regular: "inter";
$font-weight-regular: 100;
$font-weight-bold: 700;

$font-size-smaller: 0.9rem; 
$font-size-small: 1.1rem; 
$font-size-base: 1.2rem; 
$font-size-larger: ($font-size-base * 1.2); 
$font-size-largest: ($font-size-base * 1.5); 
$font-size-h1: ($font-size-base * 2.6);
$font-size-h2: ($font-size-base * 2);
$font-size-h3: ($font-size-base * 1.6);
$font-size-h4: ($font-size-base * 1.4);
$font-size-h5: ($font-size-base * 1.3);
$font-size-h6: ($font-size-base * 1.2);

// Begin new typography

$primary-font: 'Inter', sans-serif;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-medium-bold: 600;
$font-weight-bold: 700;

// Display 0 is only for desktop
@mixin display0-desktop      { font-size: 119px;   line-height: 119px;   font-weight: $font-weight-bold;   letter-spacing: -3.57px; }
@mixin display1-desktop      { font-size: 95px;    line-height: 95px;    font-weight: $font-weight-bold;   letter-spacing: -2.85px; }
@mixin display2-desktop      { font-size: 76px;    line-height: 76px;    font-weight: $font-weight-bold;   letter-spacing: -2.28px; }
@mixin display3-desktop      { font-size: 61.04px; line-height: 61.04px; font-weight: $font-weight-bold;   letter-spacing: -1.83px; }
@mixin h1-desktop            { font-size: 53px;    line-height: 68.9px;  font-weight: $font-weight-bold;   letter-spacing: -1.405px; }
@mixin h2-desktop            { font-size: 40px;    line-height: 52px;    font-weight: $font-weight-bold;   letter-spacing: -1.06px; }
@mixin h3-desktop            { font-size: 31px;    line-height: 43.4px;  font-weight: $font-weight-medium; letter-spacing: -0.775px; }
@mixin h4-desktop            { font-size: 25px;    line-height: 35px;    font-weight: $font-weight-medium; letter-spacing: -0.5px; }
@mixin body-xl-desktop       { font-size: 36px;    line-height: 50.4px;  font-weight: $font-weight-regular; }
@mixin body-xl-bold-desktop  { font-size: 36px;    line-height: 50.4px;  font-weight: $font-weight-medium-bold; }
@mixin link-xl-desktop       { font-size: 36px;    line-height: 50.4px;  font-weight: $font-weight-regular; text-decoration: underline; }
@mixin link-xl-bold-desktop  { font-size: 36px;    line-height: 50.4px;  font-weight: $font-weight-medium-bold; text-decoration: underline; }
@mixin body-lrg-desktop      { font-size: 20px;    line-height: 28px;    font-weight: $font-weight-regular; }
@mixin body-lrg-bold-desktop { font-size: 20px;    line-height: 28px;    font-weight: $font-weight-medium-bold; }
@mixin link-lrg-desktop      { font-size: 20px;    line-height: 28px;    font-weight: $font-weight-regular; text-decoration: underline; }
@mixin link-lrg-bold-desktop { font-size: 20px;    line-height: 28px;    font-weight: $font-weight-medium-bold; text-decoration: underline; }
@mixin body-reg-desktop      { font-size: 16px;    line-height: 22.4px;  font-weight: $font-weight-regular; }
@mixin body-reg-bold-desktop { font-size: 16px;    line-height: 22.4px;  font-weight: $font-weight-medium-bold; }
@mixin link-reg-desktop      { font-size: 16px;    line-height: 22.4px;  font-weight: $font-weight-regular; text-decoration: underline; }
@mixin link-reg-bold-desktop { font-size: 16px;    line-height: 22.4px;  font-weight: $font-weight-medium-bold; text-decoration: underline; }
@mixin body-sm-desktop       { font-size: 14px;    line-height: 19.6px;  font-weight: $font-weight-regular; }
@mixin body-sm-bold-desktop  { font-size: 14px;    line-height: 19.6px;  font-weight: $font-weight-medium-bold; }
@mixin link-sm-desktop       { font-size: 14px;    line-height: 19.6px;  font-weight: $font-weight-regular; text-decoration: underline; }
@mixin link-sm-bold-desktop  { font-size: 14px;    line-height: 19.6px;  font-weight: $font-weight-medium-bold; text-decoration: underline; }

// Adjusted for tablet
@mixin display3-tablet      { font-size: 50px; }

//  Adjusted styles for large mobile (786px). Styles that are not present from aboveremain as they are on mobile.
@mixin display1-mobile-lg      { font-size: 58.45px; line-height: 81.83px; letter-spacing: 0px; }
@mixin display2-mobile-lg      { font-size: 51.96px; line-height: 51.96px; letter-spacing: 0px; }
@mixin display3-mobile-lg      { font-size: 36.18px; line-height: 38.05px; letter-spacing: 0px; }
@mixin h1-mobile-lg            { font-size: 28.83px; line-height: 40.36px; letter-spacing: 0px; }
@mixin h2-mobile-lg            { font-size: 22.63px; line-height: 31.68px; letter-spacing: 0px; }
@mixin h3-mobile-lg            { font-size: 22.78px; line-height: 31.89px; font-weight: $font-weight-medium-bold; }
@mixin h4-mobile-lg            { font-size: 20.25px; line-height: 28.35px; font-weight: $font-weight-medium-bold; }
@mixin body-lrg-mobile-lg      { font-size: 16px;    line-height: 22.4px; }
@mixin body-lrg-bold-mobile-lg { font-size: 16px;    line-height: 22.4px; }
@mixin body-reg-mobile-lg      { line-height: 26.4px; }
@mixin body-reg-bold-mobile-lg { line-height: 26.4px; }

//  Adjusted styles for mobile (540px). Styles that are not present from aboveremain as they are on mobile.
@mixin display3-mobile      { font-size: 24px; }


// End new typography