@import '../../assets/styles/vars';

.split-layout__container {
    background-color: $white;

    .container-full-width {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .split-layout__title {
        color: $gray-900;
        text-align: left;
        font-size: $font-size-h3;
    }

    .split-layout__content {
        display: grid;
        grid-template-columns: 1.75fr 1fr;
        justify-content: center;
        grid-gap: 6rem;
        color: $gray-700;

        &.no-image {
            grid-template-columns: 1fr; // Full width when no image is present
        }

        .split-layout__image {
            background-size: cover; 
            background-repeat: no-repeat; 
            background-position: center center; 
            min-height: 100%;
            width: 100%;
        }

        .split-layout__text {
            display: grid;
            align-items: flex-end;
            justify-content: center;

            h3 {
                margin-bottom: 1rem;
            }
        }

        ul, ol {
            margin-left: 2rem;
            margin-bottom: 1rem;
        }
    }

    .cms-image-on-left {
        order: -1;
    }

    .split-layout__content:has(.cms-image-on-left) {
        grid-template-columns: 1fr 1.75fr;
    }

    .container-full-width.split-layout--accented,
    .container-full-width.split-layout--accented-shadow {
        border-left: 13px solid $acds-primary;
        border-right: none;
        padding: 0;

        .split-layout__content {
            padding: 0;
            grid-gap: 0;

            &.no-image {
                .split-layout__text {
                    padding: 6rem;
                }
            }

            .split-layout__image {
                background-color: $acds-primary-600;
                background-blend-mode: multiply;
                filter: contrast(2) saturate(0.5) brightness(150%);
            }
        }
    }

    .container-full-width.split-layout--accented.accent-flipped,
    .container-full-width.split-layout--accented-shadow.accent-flipped {
        border-right: 13px solid $acds-primary;
        border-left: none;
    }

    .split-layout--accented-shadow {
        box-shadow: $box-shadow-heaviest;
        position: relative;
    }
}

.split-layout__container.cms-dark-mode {
    background-color: $gray-800;
}

@media(max-width: $tabletBreakPoint) {
    .split-layout__container {
        .split-layout__content {
            grid-gap: 0rem;

            .split-layout__image {
                padding: 3rem;
            }
        }

        .split-layout__text {
            padding: 3rem;
        }

        .container-full-width.split-layout--accented {
            .split-layout__image {
                padding: 3rem;
            }

            .split-layout__text {
                padding: 3rem;
            }
        }
    }
}

@media(max-width: $largeMobileBreakPoint) {
    .split-layout__container {
        .split-layout__content {
            grid-template-columns: 1fr;
            grid-gap: 2rem;
            padding: 0 0 3rem 0;

            .split-layout__image {
                min-height: 250px;
            }

            .split-layout__image,
            .split-layout__text {
                padding: 2rem !important;
            }
        }

        .split-layout__content:has(.cms-image-on-left) {
            grid-template-columns: 1fr;
        }
        
        .cms-image-on-left {
            order: 1;
        }
    }
}

